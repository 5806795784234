import { lazy } from "react";
import IntroContent from "../../content/IntroContent.json";
import MiddleBlockContent from "../../content/MiddleBlockContent.json";
import AboutContent from "../../content/AboutContent.json";
import MissionContent from "../../content/MissionContent.json";
import ProductContent from "../../content/ProductContent.json";
import ContactContent from "../../content/ContactContent.json";
import AcdemyContent from "../../content/AcdemyContent.json";
import TourismContent from "../../content/TourismContent.json";
import EcommerceContent from "../../content/EcommerceContent.json";
import RelationshipContent from "../../content/RelationshipContent.json";

import Profile from "../../components/Profile"

const Contact = lazy(() => import("../../components/ContactForm"));
const MiddleBlock = lazy(() => import("../../components/MiddleBlock"));
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../../components/ContentBlock"));
// background: url("img/page/sh-first.jpg");
//   background-repeat: no-repeat;

const Home = () => {
  return (
    <Container>
      <ScrollToTop />
      <ContentBlock
        type="left"
        title={IntroContent.title}
        content={IntroContent.text}
        button={IntroContent.button}
        icon="project.gif"
        id="home"
      />
       {/* <div style={{background:'url(img/svg/write.gif)', backgroundRepeat: 'no-repeat', backgroundSize:'100% 100%', width:'150%',marginLeft:-200}}> */}
      <ContentBlock
        type="right"
        title={MiddleBlockContent.title}
        content={MiddleBlockContent.text}
        button={MiddleBlockContent.button}
        icon="team.gif"
        id="top"
      />
             {/* </div> */}
             <ContentBlock
        type="left"
        title={AcdemyContent.title}
        content={AcdemyContent.text}
        button={AcdemyContent.button}
        icon="project.gif"
        id="home"
      />
       {/* <div style={{background:'url(img/svg/write.gif)', backgroundRepeat: 'no-repeat', backgroundSize:'100% 100%', width:'150%',marginLeft:-200}}> */}
      <ContentBlock
        type="right"
        title={TourismContent.title}
        content={TourismContent.text}
        button={TourismContent.button}
        icon="team.gif"
        id="top"
      />
             {/* </div> */}
             <ContentBlock
        type="left"
        title={EcommerceContent.title}
        content={EcommerceContent.text}
        button={EcommerceContent.button}
        icon="project.gif"
        id="home"
      />
       {/* <div style={{background:'url(img/svg/write.gif)', backgroundRepeat: 'no-repeat', backgroundSize:'100% 100%', width:'150%',marginLeft:-200}}> */}
      <ContentBlock
        type="right"
        title={RelationshipContent.title}
        content={RelationshipContent.text}
        button={RelationshipContent.button}
        icon="team.gif"
        id="top"
      />
             {/* </div> */}
      <ContentBlock
        type="left"
        title={AboutContent.title}
        content={AboutContent.text}
        section={AboutContent.section}
        icon="developer.gif"
        id="blog"
      />
      <ContentBlock
        type="right"
        title={MissionContent.title}
        content={MissionContent.text}
        icon="computer.gif"
        id="product"
      />
      <ContentBlock
        type="left"
        title={ProductContent.title}
        content={ProductContent.text}
        icon="help.gif"
        id="team"
      />
      <Profile />
      <Contact
        title={ContactContent.title}
        content={ContactContent.text}
        id="contact"
      />
    </Container>
  );
};

export default Home;
