import { SvgIconProps } from "../types";

export const SvgIcon = ({ src, width, height, ...res }: SvgIconProps) => (
  <img
    src={`/img/svg/${src}`}
    alt={src}
    style={{
      width: width || '100%',
      height: height || 'auto',
      mixBlendMode: 'multiply',
      backgroundColor: 'transparent',
      // objectFit: 'contain',
      ...res
    }}
  />
);
