import { lazy, Suspense } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import {Route, Routes } from "react-router-dom";
import { SvgIcon } from "../common/SvgIcon";
import { Backdrop } from "@mui/material";


const Router = () => {
  return (
    <Suspense fallback={
      <Backdrop
        sx={(theme) => ({ color: 'rgb(255, 130, 92)', zIndex: theme.zIndex.drawer + 1 })}
        open={true}
      >
        <SvgIcon src="logo.gif" width="100px" height="100px" />
      </Backdrop>
    }>
      <Styles />
      <Header />
      <Routes>
          {routes.map((routeItem) => {
            return (
              <Route path={routeItem.path} element={<routeItem.element />} />
            );
          })}
      </Routes>
      <Footer />
    </Suspense>
  );
};

export default Router;
